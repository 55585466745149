<template>
  <div class="section container">
    <div>
      <header class="modal-card-head">
        <p class="card-header-title is-centered">{{ product.name }}</p>
      </header>
      <br>
      <div class="columns">
        <div class="column">
          <div class="card-content is-flex is-horizontal-center ">
            <figure class="image " v-if="loaded">
              <img v-if="product.image_path" :src='imageURL(product.image_path)' alt="image of the product">
              <img v-else src="@/assets/logo-arene.png" alt="image of the product">
            </figure>
          </div>
        </div>
        <div class="column">
          <b-field label="TCG">
            {{ product.namegame }}
          </b-field>
          <b-field label="Catégorie">
            {{ product.namecat }}
          </b-field>
          <b-field label="Langue">
            {{ product.namelang }}
          </b-field>
          <b-field label="Date de sortie">
            {{ product.showDate }}
          </b-field>
          <b-field label="Prix">
            {{ Number(product.price).toFixed(2) }} CHF / pièce
          </b-field>
          <strong>Disponibilité</strong>
          <b-field>
            <span v-if="product.stock === 1">{{ product.stock }} pièce</span>
            <span v-if="product.stock > 1">{{ product.stock }} pièces</span>
            <span v-if="product.stock <= 0 && product.is_orderable">{{ product.stock }} pièce <br> Il est tout de même possible de commander ce produit</span>
            <span v-if="product.stock <= 0 && !product.is_orderable">{{ product.stock }} pièce <br> Produit non disponible</span>
          </b-field>
          <br>
          <b-field>
            <b-select v-if="product.stock>0" v-model="product.selectedQuantity"
                      :run="!product.selectedQuantity ? product.selectedQuantity = 1 : true">
              <option v-for="index in product.stock" :key="index" @click="product.selectedQuantity = index">
                {{ index }}
              </option>
            </b-select>
            <b-select v-else-if="product.stock <= 0 && product.is_orderable" v-model="product.selectedQuantity"
                      :run="!product.selectedQuantity ? product.selectedQuantity = 1 : true">
              <option v-for="index in 100" :key="index" @click="product.selectedQuantity = index">
                {{ index }}
              </option>
            </b-select>
          </b-field>
          <b-button v-if="product.stock <= 0 && !product.is_orderable" type="is-warning">Produit indisponible</b-button>
          <b-button v-else-if="product.stock <= 0 && product.is_orderable" type="is-warning" @click="addCartSelect(product)">Sur commande</b-button>
          <b-button v-else-if="checkDateProduct(product)" type="is-success" @click="addCartSelect(product)">Ajouter au panier
          </b-button>
          <b-button v-else type="is-success" @click="addCartSelect(product)">Précommander</b-button>
        </div>
      </div>
      <div>
        <div class="card" v-if="product.description">
          <div class="card-content">
            <strong class="card-header-title is-centered">Description</strong>
            <b-field label="Description" v-html="product.description" style="white-space: pre-line;"></b-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {DateTime} from 'luxon'
import {imageMixin} from '@/mixins/image'
import notification from "@/mixins/notification";

export default {
  data: () => ({
    product: {},
    idProduct: null,
    categories: [],
    games: [],
    languages: [],
    loaded: false,
  }),
  mixins: [imageMixin, notification],
  computed: {
    ...mapGetters(['myOverStock'])
  },
  methods: {
    checkDateProduct(product) {
      const today = DateTime.now()
      if (product.date < today) {
        return true
      }
      if (product.date > today) {
        return false
      }
    },
    addCartSelect: function () {
      if (this.product.selectedQuantity > 0) {
        let i
        for (i = 0; i < this.product.selectedQuantity; i++) {
          this.$store.commit('ADD_PRODUCT', this.product)
        }
      } else {
        this.$toast.clear()
        this.$toast.open({
          message: 'Il faut choisir le nombre à ajouter au panier',
          type: 'warning',
          position: 'top'
        })
        return
      }
      if (this.myOverStock && !this.product.is_orderable) {
        this.$toast.clear()
        this.$toast.open({
          message: 'Vous ne pouvez pas dépasser le nombre total de produits en stock. Le maximum a été ajouté au panier',
          type: 'warning',
          position: 'top'
        })
      } else {
        this.$toast.clear()
        this.$toast.open({
          message: 'Ajouté au panier',
          type: 'success',
          position: 'top'
        })
      }
    },
    updateQuantity: function (product, quant) {
      if (quant.target.value > product.stock) {
        quant.target.value = product.stock
      }
      if (quant.target.value < 1) {
        quant.target.value = 1
      }
      this.quantities[product.id] = quant.target.value
    }
  },
  async mounted() {
    this.idProduct = this.$route.params.id
    await this.$store.dispatch('index', {path: 'products/', data: this.idProduct})
      .then(data => {
        this.product = data.data
        const myDate = new Date(this.product.release_date)
        const myYear = myDate.getFullYear()
        const myMonth = myDate.getMonth() + 1
        const myDay = myDate.getDate()
        this.product.date = DateTime.local(myYear, myMonth, myDay)
        this.product.showDate = new Date(this.product.release_date).toLocaleDateString()
      }).catch(() => {
        this.showError('Impossible de charger le produit.')
      });
    await this.$store.dispatch('index', {path: 'categories', data: ''})
      .then(data => {
        const myData = data.data
        this.product.namecat = null
        for (let i = 0; i < myData.length; i++) {
          if (myData[i].id === this.product.category_id) {
            this.product.namecat = myData[i].name
          }
        }
        if (this.product.namecat === null) {
          this.product.namecat = 'Dans aucune catégorie'
        }
      })
    await this.$store.dispatch('index', {path: 'games', data: ''})
      .then(data => {
        const myData = data.data
        this.product.namegame = null
        for (let i = 0; i < myData.length; i++) {
          if (myData[i].id === this.product.game_id) {
            this.product.namegame = myData[i].name
          }
        }
        if (this.product.namegame === null) {
          this.product.namegame = 'Dans aucun TCG'
        }
      })
    await this.$store.dispatch('index', {path: 'languages', data: ''})
      .then(data => {
        const myData = data.data
        this.product.namelang = null
        for (let i = 0; i < myData.length; i++) {
          if (myData[i].id === this.product.game_id) {
            this.product.namelang = myData[i].name
          }
        }
        if (this.product.namelang === null) {
          this.product.namelang = 'Langue non répertoriée'
        }
      })
    this.loaded = true
  }
}
</script>

<style scoped>

.elementLeftPart {
  margin-left: 2%;
}

</style>
